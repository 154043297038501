// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  version: '2.0.9',
  domain: 'https://poma.paellasoft.eu/',
  server: {
    empresa: 'https://empresa.poma.paellasoft.eu/api',
    cnae: 'https://cnae.poma.paellasoft.eu/api/dataCache',
    ond: 'https://ond.poma.paellasoft.eu/api',
    maquinaria: 'https://maquinaria.poma.paellasoft.eu/api',
    oauth: 'https://oauthv2.hipermercode.eu/api',
    export: 'https://export.poma.paellasoft.eu/api',
    contabilidad: 'https://contabilidad.poma.paellasoft.eu/api',
    pgc: 'https://pgc.hipermercode.eu/api',
  },
  defaultLanguage: 'es-ES',
  supportedLanguages: ['es-ES', 'en-EN'],
  logo: 'assets/img/logo.png',
  footerTextLink: 'VBRC',
  machineryGUI: true,
  PUBLIC_JWT:
    'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIwMmQ2NTg4N2RhZWQ0OWZhOTdkNzlhZWQwYTkyYzgwMyIsImlzcyI6Imh0dHBzOi8vaGlwZXJtZXJjb2RlLmNvbSIsImV4cCI6MjMyNjk1ODg0OX0.P9Il0eXvNogjJ3Upn0cdA49a06txfEAhQqlPKI8dQAs',
};
