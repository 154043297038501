import * as i1 from '@angular/common/http';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';

/**
 * HttpCriteria class.
 */
class HttpCriteria {
  constructor(defaultValue = {}) {
    this.defaultValue = defaultValue;
    this._filters = this.defaultValue._filters = defaultValue._filters || [];
    this._group = this.defaultValue._group = defaultValue._group || [];
    this._having = this.defaultValue._having = defaultValue._having || [];
    this._page = this.defaultValue._page = defaultValue._page || 1;
    this._perPage = this.defaultValue._perPage = defaultValue._perPage || 30;
    this._sort = this.defaultValue._sort = defaultValue._sort || [];
  }
  /**
   * @alias clone (non static)
   * @returns A new instance of `HttpCriteria` with the same default values
   */
  static clone(criteriaManager) {
    return criteriaManager.clone();
  }
  /**
   * Adds an item to *_filters* collection.
   */
  addFilter(filter) {
    this._filters.push(filter);
    return this;
  }
  /**
   * Adds an item to *_group* collection.
   */
  addGroup(group) {
    this._group.push(group);
    return this;
  }
  /**
   * Adds an item to *_having* collection.
   */
  addHaving(having) {
    this._having.push(having);
    return this;
  }
  /**
   * Adds an item to *_sort* collection.
   */
  addSort(sort) {
    this._sort.push(sort);
    return this;
  }
  /**
   * Changes the *_page* value.
   */
  changePage(page) {
    this._page = page;
    return this;
  }
  /**
   * Changes the *_perPage* value.
   */
  changePerPage(perPage) {
    this._perPage = perPage;
    return this;
  }
  /**
   * Clears the current *criteria*.
   */
  clear() {
    this._filters = [];
    this._group = [];
    this._having = [];
    this._page = 1;
    this._perPage = 30;
    this._sort = [];
    return this;
  }
  /**
   * @returns A new instance of `HttpCriteria` with the same default values
   */
  clone() {
    return new HttpCriteria(this.defaultValue);
  }
  /**
   * @alias toString
   * @returns The current *criteria* as string (ready for URLs)
   */
  getAsURI() {
    return this.toString();
  }
  /**
   * Removes an item specified by `index` from *_filter* collection.
   */
  removeFilter(index) {
    if (!!this._filters[index] === true) {
      this._filters.splice(index, 1);
    }
    return this;
  }
  /**
   * Removes an item specified by `index` from *_group* collection.
   */
  removeGroup(index) {
    if (!!this._group[index] === true) {
      this._group.splice(index, 1);
    }
    return this;
  }
  /**
   * Removes an item specified by `index` from *_having* collection.
   */
  removeHaving(index) {
    if (!!this._having[index] === true) {
      this._having.splice(index, 1);
    }
    return this;
  }
  /**
   * Removes an item specified by `index` from *_sort* collection.
   */
  removeSort(index) {
    if (!!this._sort[index] === true) {
      this._sort.splice(index, 1);
    }
    return this;
  }
  /**
   * Resets the current *criteria* with default values.
   */
  reset() {
    this._filters = this.defaultValue._filters;
    this._group = this.defaultValue._group;
    this._having = this.defaultValue._having;
    this._page = this.defaultValue._page;
    this._perPage = this.defaultValue._perPage;
    this._sort = this.defaultValue._sort;
    return this;
  }
  /**
   * @returns The current *criteria* as `HttpParams` object
   */
  toHttpParams() {
    return new HttpParams({
      fromObject: this.toQueryParams()
    });
  }
  /**
   * @returns The current *criteria* as plain object
   */
  toObject() {
    const criteria = {
      _filters: this._filters,
      _group: this._group,
      _having: this._having,
      _page: this._page,
      _perPage: this._perPage,
      _sort: this._sort
    };
    if (criteria._filters.length === 0) {
      delete criteria._filters;
    }
    if (criteria._group.length === 0) {
      delete criteria._group;
    }
    if (criteria._having.length === 0) {
      delete criteria._having;
    }
    if (criteria._sort.length === 0) {
      delete criteria._sort;
    }
    return criteria;
  }
  /**
   * @returns The current *criteria* as query params
   */
  toQueryParams() {
    const criteria = this.toObject();
    const queryParams = {};
    Object.keys(criteria).forEach(key => queryParams[key] = JSON.stringify(criteria[key]));
    return queryParams;
  }
  /**
   * @returns The current *criteria* as string
   */
  toString() {
    return JSON.stringify(this.toObject());
  }
  /**
   * @returns The current *criteria* as string (formatted as query params)
   */
  toStringQueryParams() {
    const criteria = this.toObject();
    return Object.keys(criteria).map(key => `${key}=${JSON.stringify(criteria[key])}`).join('&');
  }
}
class SuperHttpHeaders {
  /////////////////
  // Constructor //
  /////////////////
  /**
   * @param headers The headers to add to the default collection
   */
  constructor(headers) {
    /**
     * Collection of registered headers. **Don't modify it directly!**
     *
     * @internal
     */
    this._headers = new Map();
    this._default = headers;
    this.setDefaults();
  }
  ///////////////////////
  // Private Functions //
  ///////////////////////
  /// code...
  ///////////////////////
  // GET/SET Functions //
  ///////////////////////
  /// code...
  //////////////////////
  // Public Functions //
  //////////////////////
  /**
   * Removes all registered headers from the collection.
   */
  clear() {
    this._headers.clear();
  }
  /**
   * Removes the specified registered header from the collection.
   *
   * @param key The key of the header to remove from the collection
   * @returns `true` if a registered header in the collection existed and has been removed,
   * or `false` if the header does not exist
   */
  delete(key) {
    return this._headers.delete(key);
  }
  /**
   * Returns the specified registered header from the collection.
   *
   * @param key The key of the header to return from the collection
   * @returns The registered header associated with the specified key or `undefined` if the
   * key can't be found in the collection
   */
  get(key) {
    return this._headers.get(key);
  }
  /**
   * Returns all the registered headers from the collection.
   *
   * @param tempHeaders The temporal headers to be joined with the registered headers
   * @returns The registered headers from the collection
   */
  getAll(tempHeaders = {}) {
    const hs = {};
    this._headers.forEach((v, k) => hs[k] = v);
    return {
      ...hs,
      ...tempHeaders
    };
  }
  /**
   * Return a boolean indicating whether a registered header with the specified key exists or not.
   *
   * @param key The key of the header to test for presence in the collection
   * @returns `true` if a registered header with the specified key exists in the collection;
   * otherwise `false`
   */
  has(key) {
    return this._headers.has(key);
  }
  /**
   * Removes all registered headers from the collection and adds the default headers again.
   */
  reset() {
    this.clear();
    this.setDefaults();
  }
  /**
   * Adds or updates a header with a specified key and value to the collection.
   *
   * @param key The key of the header to add or update to the collection
   * @param value The value of the header to add or update to the collection
   * @returns The registered header just added or updated
   */
  set(key, value) {
    this._headers.set(key, value);
    return this.get(key);
  }
  /**
   * Adds or updates the default headers to the collection of registered headers.
   *
   * @returns void
   */
  setDefaults() {
    Object.keys(this._default).forEach(k => this.set(k, this._default[k]));
  }
}

/**
 * Construct an instance of `HttpRequestOptions<T>` from a source `HttpMethodOptions` and
 * the given `body`. Basically, this clones the object and adds the body.
 */
function addBody(options, body) {
  return {
    body,
    headers: options.headers,
    observe: options.observe,
    params: options.params,
    reportProgress: options.reportProgress,
    responseType: options.responseType,
    withCredentials: options.withCredentials
  };
}
class SuperHttpClient {
  /////////////////
  // Constructor //
  /////////////////
  constructor(http, BASE_URL = '', headers = {}) {
    this.http = http;
    this.BASE_URL = BASE_URL;
    this.headers = new SuperHttpHeaders(headers);
  }
  ///////////////////////
  // Private Functions //
  ///////////////////////
  /// code...
  /////////////////////////
  // Protected Functions //
  /////////////////////////
  getHeaders(tempHeaders = {}) {
    let hs = {};
    if (tempHeaders instanceof HttpHeaders) {
      tempHeaders.keys().forEach(k => hs[k] = tempHeaders.getAll(k));
    } else {
      hs = tempHeaders;
    }
    let _headers = new HttpHeaders(this.headers.getAll());
    Object.keys(hs).forEach(k => _headers = _headers.append(k, hs[k]));
    return _headers;
  }
  /**
   * Constructs an `Observable` for a particular HTTP request that, when subscribed, fires the
   * request through the chain of registered interceptors and on to the server.
   *
   * This method can be called in one of two ways. Either an `HttpRequest` instance can be
   * passed directly as the only parameter, or a method can be passed as the first parameter, a
   * string URL as the second, and an options hash as the third.
   *
   * If a `HttpRequest` object is passed directly, an `Observable` of the raw `HttpEvent`
   * stream will be returned. **The URL won't use *shortener* feature.**
   *
   * If a request is instead built by providing a URL, the options object determines the return
   * type of `request()`. In addition to configuring request parameters such as the outgoing
   * headers and/or the body, the options hash specifies two key pieces of information about the
   * request: the `responseType` and what to `observe`. **The URL will use *shortener* feature.**
   */
  request(first, url, options = {}) {
    if (typeof first === 'string') {
      options.headers = this.getHeaders(options.headers);
      return this.http.request(first, `${this.BASE_URL}${url}`, options);
    } else {
      return this.http.request(first);
    }
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * DELETE request to be executed on the server. See the individual overloads for
   * details of `delete()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   */
  delete(url, options = {}) {
    return this.request('DELETE', url, options);
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * GET request to be executed on the server. See the individual overloads for
   * details of `get()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   *
   * A `HttpCriteria` object is mandatory, else use `get` function.
   */
  filter(url, criteria, options = {}) {
    return this.request('GET', url, {
      ...options,
      params: criteria.toHttpParams()
    });
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * GET request to be executed on the server. See the individual overloads for
   * details of `get()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   */
  get(url, options = {}) {
    return this.request('GET', url, options);
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * HEAD request to be executed on the server. See the individual overloads for
   * details of `head()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   */
  head(url, options = {}) {
    return this.request('HEAD', url, options);
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause a request
   * with the special method `JSONP` to be dispatched via the interceptor pipeline.
   * **The URL will use *shortener* feature.**
   *
   * A suitable interceptor must be installed (e.g. via the `HttpClientJsonpModule`).
   * If no such interceptor is reached, then the `JSONP` request will likely be
   * rejected by the configured backend.
   */
  jsonp(url, callbackParam) {
    return this.request('JSONP', url, {
      observe: 'body',
      params: new HttpParams().append(callbackParam, 'JSONP_CALLBACK'),
      responseType: 'json'
    });
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * OPTIONS request to be executed on the server. See the individual overloads for
   * details of `options()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   */
  options(url, options = {}) {
    return this.request('OPTIONS', url, options);
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * PATCH request to be executed on the server. See the individual overloads for
   * details of `patch()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   */
  patch(url, body, options = {}) {
    return this.request('PATCH', url, addBody(options, body));
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * POST request to be executed on the server. See the individual overloads for
   * details of `post()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   */
  post(url, body, options = {}) {
    return this.request('POST', url, addBody(options, body));
  }
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * PUT request to be executed on the server. See the individual overloads for
   * details of `put()`'s return type based on the provided options. **The URL
   * will use *shortener* feature.**
   */
  put(url, body, options = {}) {
    return this.request('PUT', url, addBody(options, body));
  }
}
const SHC_DOMAINS = new InjectionToken('SHC_DOMAINS');
class SuperHttpClientProvider {
  /////////////////
  // Constructor //
  /////////////////
  /**
   * The first domain will be set as default.
   *
   * @param domains `{ [key: string]: ISuperDomain; }`
   */
  constructor(ngHttp, domains) {
    this.ngHttp = ngHttp;
    /**
     * Collection of registered domains. **Don't modify it directly!**
     *
     * @internal
     */
    this._domains = new Map();
    Object.keys(domains).forEach((k, i) => this.set(k, domains[k], i === 0));
  }
  ///////////////////////
  // Private Functions //
  ///////////////////////
  /// code...
  ///////////////////////
  // GET/SET Functions //
  ///////////////////////
  /**
   * A shorthand for the default registered domain.
   */
  get default() {
    return this._domains.get(this._default);
  }
  //////////////////////
  // Public Functions //
  //////////////////////
  /**
   * Removes all registered domains from the collection.
   */
  clear() {
    this._domains.clear();
  }
  /**
   * Removes the specified registered domain from the collection.
   *
   * @param key The key of the domain to remove from the collection
   * @returns `true` if a registered domain in the collection existed and has been removed,
   * or `false` if the domain does not exist
   */
  delete(key) {
    return this._domains.delete(key);
  }
  /**
   * Returns the specified registered domain from the collection.
   *
   * @deprecated Instead make use of [use]{SuperHttpClientProvider#use}
   *
   * @param key The key of the domain to return from the collection
   * @returns The registered domain associated with the specified key or `undefined` if the
   * key can't be found in the collection
   */
  get(key) {
    return this._domains.get(key);
  }
  /**
   * Return a boolean indicating whether a registered domain with the specified key exists or
   * not.
   *
   * @param key The key of the domain to test for presence in the collection
   * @returns `true` if a registered domain with the specified key exists in the collection;
   * otherwise `false`
   */
  has(key) {
    return this._domains.has(key);
  }
  /**
   * Adds or updates a domain with a specified key and value to the collection.
   *
   * @param key The key of the domain to add or update to the collection
   * @param value The value of the domain to add or update to the collection
   * @param isDefault Set the registered domain as default
   * @returns The registered domain just added or updated
   */
  set(key, value, isDefault = false) {
    if (typeof value === 'object') {
      this._domains.set(key, new SuperHttpClient(this.ngHttp, value.url, value.headers));
      isDefault = isDefault || value.isDefault;
    } else {
      this._domains.set(key, new SuperHttpClient(this.ngHttp, value));
    }
    if (isDefault === true) {
      this._default = key;
    }
    return this._domains.get(key);
  }
  toArray() {
    const domains = [];
    this._domains.forEach((domain, key) => domains.push({
      key,
      domain
    }));
    return domains;
  }
  /**
   * Returns the specified registered domain from the collection.
   *
   * @param key The key of the domain to return from the collection
   * @returns The registered domain associated with the specified key or `undefined` if the
   * key can't be found in the collection
   */
  use(key) {
    return this._domains.get(key);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.13",
      ngImport: i0,
      type: SuperHttpClientProvider,
      deps: [{
        token: i1.HttpClient
      }, {
        token: SHC_DOMAINS
      }],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "18.2.13",
      ngImport: i0,
      type: SuperHttpClientProvider
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.13",
  ngImport: i0,
  type: SuperHttpClientProvider,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [SHC_DOMAINS]
    }]
  }]
});
/**
 * Factory of `SuperHttpClientProvider`.
 */
function factory(ngHttp, domains) {
  return new SuperHttpClientProvider(ngHttp, domains);
}
class SuperHttpClientModule {
  static forRoot(config = {}) {
    return {
      ngModule: SuperHttpClientModule,
      providers: [{
        provide: SHC_DOMAINS,
        useValue: config.domains || {}
      }, {
        provide: SuperHttpClientProvider,
        useFactory: factory,
        deps: [HttpClient, SHC_DOMAINS]
      }]
    };
  }
  /**
   * Not tested. **Use it at your own risk.**
   *
   * @todo
   */
  static forChild(config = {}) {
    return {
      ngModule: SuperHttpClientModule,
      providers: [{
        provide: SHC_DOMAINS,
        useValue: config.domains || {}
      }]
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.13",
      ngImport: i0,
      type: SuperHttpClientModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.13",
      ngImport: i0,
      type: SuperHttpClientModule
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.13",
      ngImport: i0,
      type: SuperHttpClientModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.13",
  ngImport: i0,
  type: SuperHttpClientModule,
  decorators: [{
    type: NgModule
  }]
});

/*
 * Public API Surface of super-http-client
 */

/**
 * Generated bundle index. Do not edit.
 */

export { HttpCriteria, SHC_DOMAINS, SuperHttpClientModule, SuperHttpClientProvider, factory };
